<template>
  <div class="moreSensorDailySurvey">
    <div class="title">当日运行状况</div>
    <table cellpadding="0" cellspacing="0" class="table">
      <thead>
        <tr>
          <th colspan="2">{{ equipStateInfo.projectName }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>设备：{{ equipStateInfo.equipmentName }}</td>
          <td v-if="!equipStateInfo.isAlarm">报警：</td>
          <td v-else>报警：{{ equipStateInfo.isAlarm == 1 ? '是' : '否' }}</td>
        </tr>
        <tr>
          <td>风险程度: {{ equipStateInfo.riskLevel }}</td>
          <td>平均损伤值：{{ equipStateInfo.avgDamage }}</td>
        </tr>
        <tr>
          <td>损伤个数：{{ typeof equipStateInfo.damageCount == 'undefined' ? '' : equipStateInfo.damageCount }}</td>
          <td>当日运行时长：{{ typeof equipStateInfo.runningTime == 'undefined' ? '' : equipStateInfo.runningTime }}</td>
        </tr>
        <tr>
          <td>当日运行里程：{{ typeof equipStateInfo.runningDistance == 'undefined' ? '' : equipStateInfo.runningDistance }}</td>
          <td style="color:#0099FF;">责任人：{{ equipStateInfo.contactPerson }}</td>
        </tr>

        <template v-if="Array.isArray(damageLocationArr)">
          <tr>
            <div class="location">风险损伤位置：</div>
          </tr>
          <tr v-for="(item1, index1) in damageLocationArr" :key="item1[0].serial">
            <td :colspan="damageLocationArr[0].length == 1 ? 2 : ''" :class="{ locationTd: index1 == 0 ? true : false }" v-for="item2 in item1">
              {{ item2.serial + ':' + item2.location }}
            </td>
          </tr>
        </template>
        <template v-if="typeof damageLocationArr == 'string'">
          <tr>
            <td colspan="2">风险损伤位置：{{ damageLocationArr }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'MoreSensorDailySurvey',
  data() {
    return {
      // 当前设备的运行状况
      equipStateInfo: {}
    }
  },
  computed: {
    damageLocationArr() {
      let { damageLocation } = this.equipStateInfo
      if (damageLocation) {
        if (damageLocation.indexOf('|') !== -1) {
          let arr = damageLocation.split('|')
          let arr1 = []
          let arr2 = []
          for (let i = 0; i < arr.length; i++) {
            let obj = {
              serial: arr[i].split(':')[0],
              location: arr[i].split(':')[1]
            }
            arr1.push(obj)
          }
          for (let j = 0; j < arr1.length; j += 2) {
            arr2.push(arr1.slice(j, j + 2))
          }
          return arr2
        } else if (damageLocation.indexOf(':') !== -1) {
          return [[{ serial: damageLocation.split(':')[0], location: damageLocation.split(':')[1] }]]
        } else {
          return damageLocation
        }
      }
      return []
    }
  },
  methods: {
    async getEquipInfo(res) {
      this.equipStateInfo = {}
      if (res.data.resultCode === '0000') {
        this.equipStateInfo = res.data.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.moreSensorDailySurvey {
  width: 100%;
  height: 100%;
  position: relative;
  .title {
    display: block;
    margin-left: 18px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 18px;
  }

  .table {
    width: 65.21%;
    max-width: 900px;
    position: absolute;
    left: 50%;
    top: 60px;
    font-size: 14px;
    transform: translateX(-50%);
    background-color: #1c1f30;
    color: #fefefe;
    thead {
      width: 100%;
      text-align: center;
      tr {
        width: 100%;
        th {
          width: 50%;
          height: 32px;
          background-color: #292b3d;
        }
      }
    }
    tbody {
      tr {
        td {
          box-sizing: border-box;
          padding-left: 6px;
          height: 40px;
          border-right: 1px solid #292b3d;
          border-bottom: 1px solid #292b3d;
        }
        td:nth-child(1) {
          border-left: 1px solid #292b3d;
        }
      }
      .location {
        padding: 4px 0 4px 4px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .table {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1366px) {
    .table {
      width: 90%;
    }
  }
  .riskHoverContent {
    display: flex;
    .riskSerial {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    .riskLocation {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
}
.locationTd {
  border-top: 1px solid #292b3d;
}
</style>
